import useAuth from 'hooks/useAuth';
import React, { useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { IS_DEV } from 'utils/constants';
import Button from './Button';
import Modal from './Modal';
import ModalMessage from './modals/ModalMessage';

export const SessionTimer = ({ isMeeting = false }: { isMeeting?: boolean }) => {
  const { logout } = useAuth();

  const timeout = 1000 * 60 * 15; // 15 minutes
  const promptBeforeIdle = 1000 * 30; // 30 seconds
  // const timeout = 1000 * 30; // 30 seconds
  // const promptBeforeIdle = 1000 * 5; // 5 seconds
  const [state, setState] = useState<string>('Active');
  const [remaining, setRemaining] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [hide, setHide] = useState<boolean>(false);

  const [count, setCount] = useState<number>(0);

  const onIdle = () => {
    setOpen(false);
    setRemaining(0);
    logout();
  };

  const onActive = () => {
    setState('Active');
    setOpen(false);
    setRemaining(0);
  };

  const onAction = () => setCount(count + 1);

  const onPrompt = () => {
    setState('Prompted');
    setOpen(true);
  };

  const onPause = () => {
    setState('Paused');
    pause();
  };

  const onResume = () => {
    setState('Active');
    resume();
  };

  const { getRemainingTime, activate, pause, resume, message } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    onAction,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
    leaderElection: isMeeting,
    onMessage: () => handleMessage()
  });

  const handleMessage = useCallback(() => activate(), [activate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  const handleLoggedIn = () => {
    setOpen(false);
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);

  const formatRemainingTime = (remaining: number) => {
    const minutes = Math.floor(remaining / 60);
    const seconds = remaining % 60;
    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    if (isMeeting) {
      handleMessage();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    message;
  }, [message, isMeeting, handleMessage]);

  return (
    <>
      {IS_DEV && (
        <div className="border border-red-500 bg-white fixed bottom-5 right-5 z-[9999] p-2 space-y-2">
          {!hide ? (
            <>
              <div className="font-bold text-center uppercase">For development purposes only</div>
              <p>Current State: {state}</p>
              <p>Action Events: {count}</p>
              <p>Remaining: {formatRemainingTime(remaining)}</p>
              {timeTillPrompt > 0 && <p>Until Prompt: {formatRemainingTime(timeTillPrompt)}</p>}

              <div className="space-y-2">
                <div className="flex items-center space-x-2 justify-evenly">
                  <Button
                    className="w-full"
                    classNameButton="justify-center"
                    onClick={() => (state === 'Paused' ? onResume() : onPause())}
                  >
                    {state === 'Paused' ? 'Resume' : 'Pause'}
                  </Button>
                  <Button
                    className="w-full"
                    classNameButton="justify-center"
                    onClick={() => setOpen(true)}
                  >
                    Modal
                  </Button>
                  <Button
                    className="w-full"
                    classNameButton="justify-center"
                    onClick={() => setHide(!hide)}
                  >
                    Hide
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <Button
              className="w-full"
              classNameButton="justify-center"
              onClick={() => setHide(!hide)}
            >
              Show
            </Button>
          )}
        </div>
      )}

      <Modal open={open} hasClose={false} round="lg">
        <ModalMessage
          variant="warning"
          message={`You're idle, logging out in ${remaining} seconds...`}
          close={() => handleLoggedIn()}
          customClose={
            <Button variant="green-btn-200" onClick={handleLoggedIn}>
              Stay logged in
            </Button>
          }
        />
      </Modal>
    </>
  );
};
