import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import React, { FC, ReactNode } from 'react';

interface IModalMessage {
  variant?: 'error' | 'warning' | 'success';
  message: string | ReactNode;
  buttonText?: string;
  customClose?: ReactNode;
  close: () => void;
}

const variants = {
  error: <ExclamationCircleIcon className="h-16 w-16 text-red-400" />,
  warning: <ExclamationIcon className="h-16 w-16 text-yellow-400" />,
  success: <CheckCircleIcon className="h-16 w-16 text-green-400" />
};

const ModalMessage: FC<IModalMessage> = ({
  variant = 'error',
  message,
  close,
  customClose,
  buttonText
}) => {
  return (
    <div className="p-5 space-y-5 text-center">
      <div className="flex justify-center">{variants[variant]}</div>
      <div className="text-lg text-polln-green-400">{message}</div>
      <div className="justify-center flex items-center">
        {customClose ? (
          customClose
        ) : (
          <Button
            type="button"
            className="w-32"
            variant="green-btn-100"
            rounded="full"
            classNameButton="justify-center uppercase"
            onClick={() => close()}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModalMessage;
