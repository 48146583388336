import React, { FC, useEffect, useState } from 'react';

// components
import ZoomVideo from '@zoom/videosdk';
import PageWrap from 'components/PageWrap';
import ZoomContext from 'contexts/ZoomContext';

import { useQuery } from '@apollo/client';
import { SessionTimer } from 'components/SessionTimer';
import { toast } from 'components/Toast';
import ZoomVideoOutlet from 'components/ZoomVideoOutlet';
import query from 'graphql/query';
import { useParams } from 'react-router-dom';
import { CONF_ZOOM_KEY, CONF_ZOOM_SECRET } from 'utils/config';
import { fullName } from 'utils/helpers';
import { IConsultationDetails } from 'utils/models';
import { b64DecodeUnicode, generateVideoToken } from 'utils/zoomHelpers';

const zmClient = ZoomVideo.createClient();

const ZoomVideoLayout: FC = () => {
  const { id } = useParams();
  const [isError, setIsError] = useState<boolean>(false);

  const { data: dataConsultation } = useQuery<{
    consultationDetails: {
      code: string;
      success: boolean;
      message: string;
      consultation: IConsultationDetails;
    };
  }>(query.consultationDetails, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  });
  const consultationDetails = dataConsultation?.consultationDetails.consultation;

  // eslint-disable-next-line no-restricted-globals
  let meetingArgs: any = Object.fromEntries(new URLSearchParams(location.search));

  // Add enforceGalleryView to turn on the gallery view without SharedAddayBuffer
  if (
    (!meetingArgs.sdkKey || !meetingArgs.topic || !meetingArgs.name || !meetingArgs.signature) &&
    consultationDetails
  ) {
    // if (!meetingArgs.sdkKey || !meetingArgs.topic || !meetingArgs.name || !meetingArgs.signature) {
    const zoomConfig = {
      sdkKey: CONF_ZOOM_KEY,
      sdkSecret: CONF_ZOOM_SECRET,
      webEndpoint: 'zoom.us',
      topic: `session-${consultationDetails?.reference_id}-${id}`,
      name: `Dr. ${fullName(consultationDetails?.doctor || null)}`,
      password: '',
      signature: '',
      sessionKey: '',
      userIdentity: ``,
      // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
      role: 1
    };

    // if (consultationDetails) {
    //   zoomConfig.topic = `session-${consultationDetails?.reference_id}-${id}`;
    //   zoomConfig.name = `Dr. ${fullName(consultationDetails?.doctor || null)}`;
    // }

    meetingArgs = { ...zoomConfig, ...meetingArgs };
  }

  if (meetingArgs.web && meetingArgs.web !== '0') {
    ['topic', 'name', 'password', 'sessionKey', 'userIdentity'].forEach((field) => {
      if (Object.hasOwn(meetingArgs, field)) {
        try {
          meetingArgs[field] = b64DecodeUnicode(meetingArgs[field]);
        } catch (e) {
          console.log('ingore base64 decode', field, meetingArgs[field]);
        }
      }
    });
    if (meetingArgs.role) {
      meetingArgs.role = parseInt(meetingArgs.role, 10);
    } else {
      meetingArgs.role = 1;
    }
  }
  ['enforceGalleryView', 'enforceVB', 'cloud_recording_option', 'cloud_recording_election'].forEach(
    (field) => {
      if (Object.hasOwn(meetingArgs, field)) {
        try {
          meetingArgs[field] = Number(meetingArgs[field]);
        } catch (e) {
          meetingArgs[field] = 0;
        }
      }
    }
  );
  if (meetingArgs?.telemetry_tracking_id) {
    try {
      meetingArgs.telemetry_tracking_id = b64DecodeUnicode(meetingArgs.telemetry_tracking_id);
    } catch (e) {}
  } else {
    meetingArgs.telemetry_tracking_id = '';
  }

  if (!meetingArgs.signature && meetingArgs.sdkSecret && meetingArgs.topic) {
    meetingArgs.signature = generateVideoToken(
      meetingArgs.sdkKey,
      meetingArgs.sdkSecret,
      meetingArgs.topic,
      meetingArgs.sessionKey,
      meetingArgs.userIdentity,
      Number(meetingArgs.role ?? 1),
      meetingArgs.cloud_recording_option,
      meetingArgs.cloud_recording_election,
      meetingArgs.telemetry_tracking_id
    );

    console.log('=====================================');
    console.log('meetingArgs', meetingArgs);

    const urlArgs: any = {
      topic: meetingArgs.topic,
      name: meetingArgs.name,
      password: meetingArgs.password,
      sessionKey: meetingArgs.sessionKey,
      userIdentity: meetingArgs.userIdentity,
      role: meetingArgs.role || 1,
      cloud_recording_option: meetingArgs.cloud_recording_option || '',
      cloud_recording_election: meetingArgs.cloud_recording_election || '',
      telemetry_tracking_id: meetingArgs.telemetry_tracking_id || '',
      enforceGalleryView: 0,
      enforceVB: 0,
      web: '1'
    };
    console.log('use url args');
    console.log(window.location.origin + '/?' + new URLSearchParams(urlArgs).toString());
  }

  useEffect(() => {
    if (dataConsultation?.consultationDetails.success === false) {
      toast({
        title: 'Error',
        // message: dataConsultation.consultationDetails.message,
        message: 'You are not authorized to make this consultation',
        variant: 'error'
      });
      setIsError(true);
    }
  }, [dataConsultation]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/coi-serviceworker.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <PageWrap title="On going Consultation">
      <SessionTimer isMeeting />
      <ZoomContext.Provider value={zmClient}>
        <ZoomVideoOutlet
          meetingArgs={meetingArgs}
          consultationDetails={consultationDetails}
          isError={isError}
        />
      </ZoomContext.Provider>
    </PageWrap>
  );
};

export default ZoomVideoLayout;
